import * as React from 'react';
export function IconBikeRepair(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 299 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={true}
      {...props}
    >
      <path
        d="M203.669 36.086c2.538 0 6.345 5.076 7.614 6.7l-3.173 4.264c-5.71 0-13.689-2.896-12.69-7.31.689-3.045 7.826-1.624 10.152 0-1.692-1.42-4.441-3.654-1.903-3.654z"
        fill="#C88B62"
      />
      <path d="M98.41 69.292h-4.757v120.113h4.757V69.292z" fill="#D9D9D9" />
      <path d="M111.492 187.027h-30.92v2.379h30.92v-2.379z" fill="#000" />
      <path
        d="M180.304 37.053l-8.753 76.803M206.176 51.395l-60.498 48.119M214.329 79.831l-76.803-8.754M199.987 105.703l-48.119-60.498M194.667 41.65l-37.479 67.608M213.081 64.802l-74.307 21.304M209.731 94.193l-67.607-37.478M186.579 112.608l-21.304-74.307"
        stroke="#D5D5D5"
        strokeWidth={0.5}
      />
      <path
        d="M175.927 112.294c20.674 0 37.434-16.76 37.434-37.434 0-20.674-16.76-37.434-37.434-37.434-20.674 0-37.434 16.76-37.434 37.434 0 20.674 16.76 37.434 37.434 37.434z"
        stroke="#00A9E0"
        strokeWidth={6}
      />
      <path
        d="M175.927 79.617a4.757 4.757 0 100-9.514 4.757 4.757 0 000 9.514z"
        fill="#6E7893"
      />
      <path
        d="M243.334 103.65l-8.753 76.804M269.206 117.992l-60.498 48.119M277.359 146.428l-76.804-8.753M263.016 172.301l-48.118-60.498M257.697 108.248l-37.479 67.607M276.11 131.4l-74.307 21.304M272.761 160.791l-67.607-37.479M249.609 179.205l-21.304-74.307"
        stroke="#EDEDED"
        strokeWidth={0.5}
      />
      <path
        d="M238.957 178.891c20.674 0 37.434-16.76 37.434-37.434 0-20.674-16.76-37.434-37.434-37.434-20.674 0-37.434 16.76-37.434 37.434 0 20.674 16.76 37.434 37.434 37.434z"
        stroke="#EDEDED"
        strokeWidth={6}
      />
      <path
        d="M238.957 146.214a4.757 4.757 0 100-9.515 4.757 4.757 0 000 9.515z"
        fill="#EDEDED"
      />
      <path
        d="M45.92 57.27l-8.754 76.803M71.792 71.612l-60.498 48.119M79.945 100.048L3.14 91.295M65.603 125.92l-48.12-60.498M60.282 61.868l-37.478 67.607M78.696 85.019L4.39 106.323M75.347 114.411L7.739 76.932M52.195 132.825L30.891 58.518"
        stroke="#D5D5D5"
        strokeWidth={0.5}
      />
      <path
        d="M41.327 132.889c20.674 0 37.434-16.76 37.434-37.434 0-20.674-16.76-37.434-37.434-37.434-20.675 0-37.435 16.76-37.435 37.434 0 20.674 16.76 37.434 37.435 37.434z"
        stroke="#000"
        strokeWidth={6}
      />
      <path
        d="M124.037 27.64c-1.299-1.669 2.433-4.77 4.375-5.896l1.676 1.874c-1.476 2.037-4.752 5.692-6.051 4.023z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.349}
      />
      <path
        d="M129.975 18.776c.944-1.351 3.144-3.973 4.287-5.266l1.321 1.764-3.363 4.707c-.558.807-.312 1.342.195 2.41.734 1.548 3.299 6.825 4.49 9.27l-2.313.533-4.34-8.52c-1.766.586-2.127-.798-2.087-1.564l1.13-1.477c.038-.639.259-1.255.68-1.857z"
        fill="#D5D5D5"
      />
      <path
        d="M126.759 13.284c.048-2.516 6.214-1.818 8.367-1.091 1.781.601 1.501 2.287.393 3.156-3.167.255-8.813.711-8.76-2.065z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.349}
      />
      <path
        d="M71.562 35.59l4.352 11.95-2.694 1.45-4.283-12.572 2.625-.828z"
        fill="#D5D5D5"
      />
      <path
        d="M76.714 46.65L94.843 93.67H89.56c-4.328-12.342-14.382-37.567-17.462-45.274a2.425 2.425 0 011.387-3.175 2.493 2.493 0 013.229 1.427zM137.656 30.047l28.23 58.057a2.423 2.423 0 01-4.356 2.121L133.166 32.05l4.49-2.003z"
        fill="#6E7893"
      />
      <path
        d="M85.33 72.859l50.583-35.765 1.796 3.73-51.19 36.198-1.19-4.163z"
        fill="#6E7893"
      />
      <path
        d="M59.86 33.282c-5.053-1.322-7.344 1.392-6.075 3.788 4.699 8.867 21.403.56 27.139-1.449 3.555-1.245 4.353-5.445-4.066-3.023-6.061 1.743-11.946 2.007-16.998.684z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.349}
      />
      <path
        d="M138.25 40.155l5.256 7.975-39.744 49.703-9.668-4.769 44.156-52.909z"
        fill="#6E7893"
      />
      <path
        d="M100.181 78.431l-.046.996a5.81 5.81 0 001.9 4.565l2.581 2.342 19.682-24.468-.337-.375a5.808 5.808 0 00-5.456-1.815l-.307.062a5.808 5.808 0 00-2.958 1.575l-13.35 13.26a5.804 5.804 0 00-1.709 3.858z"
        fill="#000"
        stroke="#000"
        strokeWidth={0.349}
      />
      <path
        d="M94.843 114.172c5.739 0 10.392-4.652 10.392-10.392 0-5.74-4.653-10.392-10.392-10.392-5.74 0-10.393 4.652-10.393 10.392 0 5.74 4.653 10.392 10.393 10.392z"
        stroke="#000"
        strokeWidth={3}
      />
      <path
        d="M41.326 100.212a4.757 4.757 0 100-9.514 4.757 4.757 0 000 9.514z"
        fill="#6E7893"
      />
      <path
        d="M94.842 103.78l12.487 14.865m4.163 0h-8.92"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M96.626 93.077l-55.3-4.757a7.135 7.135 0 00-2.378 13.864l53.516 12.299"
        stroke="#000"
        strokeWidth={1.5}
      />
      <path
        d="M73.469 51.599L40.138 94.266c.689.146 1.813.785 2.378 1.189l32.541-41.438-1.588-2.418z"
        fill="#6E7893"
      />
      <path d="M92.665 65.79l6.978-4.785v11.962h-6.978V65.79z" fill="#000" />
      <path
        d="M232.754 23.227l1.991 2.39c1.461 1.327 4.939 3.186 7.17 0 2.23-3.187.929-7.435 0-9.16-3.505-.957-5.178-5.71-5.576-7.966-7.037-3.718-20.393-7.568-17.525 6.77 2.868 14.338 10.488 11.285 13.94 7.966z"
        fill="#C88B62"
      />
      <path
        d="M235.143 19.642c-.637 1.593-1.859 3.054-2.39 3.585l1.992 2.39c.955-1.912.663-4.78.398-5.975z"
        fill="#9E6045"
      />
      <path
        d="M247.49 182.58v-1.593h8.364c3.823 4.78 4.413 10.754.726 10.754h-27.422c-5.283-1.741 1.998-6.307 18.332-9.161zM276.42 185.378l-.548-2.556 8.45-1.073c4.962 2.305 7.935 10.035 3.819 10.757l-25.615 2.845c-4.757.595-2.379-5.946 13.894-9.973z"
        fill="#000"
      />
      <path
        d="M295.682 69.428L263.82 83.766l-19.013 36.684c-1.195 2.125-3.206 6.244-2.25 12.935.956 6.691 3.34 47.602 3.34 47.602h11.55l1.703-52.968 9.597-15.134c-1.219 5.256-1.972 7.864-2.57 10.377-1.593 6.691-.664 10.09 0 11.949l7.998 48.166 12.73-1.702-5.544-52.789c3.35-9.157 7.165-15.47 12.33-27.595 7.328-17.206 4.381-28.411 1.991-31.863z"
        fill="#6E7893"
      />
      <path
        d="M274.256 88.608c-1.41 6.134-3.484 16.713-5.51 25.452l-9.596 13.959-1.703 52.968s-4.526-35.637-5.124-44.628c-.45-6.767 1.125-10.122 2.25-11.809l19.683-35.942z"
        fill="#545C73"
      />
      <path
        d="M241.914 16.058c-.319 10.196-4.912 10.62-7.169 9.559l-1 24.636-23.139-8.225-2.937 4.113 28.2 17.037-19.724 22.763 3.861 6.612s26.438-13.512 33.458-30.692l10.356 21.905L299 67.943c-22.304-42.377-48.058-51.885-57.086-51.885z"
        fill="#00A9E0"
      />
      <path
        d="M225.186 12.872c2.231 0 4.116 1.858 4.78 2.788-.797-2.39.796-5.178 3.186-4.78 1.912.319 2.39 2.39 1.991 3.983 2.549.319 3.187-.664 3.187-1.195-.531-.53-1.466-3.428-1.594-5.974-.398-7.966-11.948-10.355-17.126-2.788-2.226 3.254-8.349 4.78-6.438 7.966 2.39 3.982 9.226 0 12.014 0z"
        fill="#000"
      />
      <path
        d="M258.782 37.167c-.922 9.722-1.839 15.091-5.318 24.295l10.356 21.906c5.098-25.49-1.985-41.687-5.038-46.201zM234.347 37.167c.398 2.39 5.042 5.889 11.228-5.92-2.538 15.182-2.955 21.689-9.706 31.931l-28.787-17.037 4.112-5.897 22.559 10.009.476-10.009.118-3.077z"
        fill="#008FBD"
      />
      <path
        d="M283.579 78.134c-1.273 3.215-1.877 9.952 5.892 11.179l-5.892-11.179z"
        fill="#545C73"
      />
      <path
        d="M220.924 18.312a.562.562 0 100-1.124.562.562 0 000 1.124zM224.298 17.187a.562.562 0 100-1.124.562.562 0 000 1.124z"
        fill="#000"
      />
      <path
        d="M204.072 86.967c3.271-1.887 9.734-.786 12.265 0l2.92 5.306c-12.848 7.665-20.441 4.127-18.689 1.179 1.402-2.358 6.425-4.127 8.761-4.716-2.804 0-4.673-1.18-5.257-1.769z"
        fill="#C88B62"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.43 189.405a4.757 4.757 0 004.121-2.378h27.436a4.756 4.756 0 006.067 1.963l-2.58-2.58a1.694 1.694 0 012.396-2.395l.009.009 2.57 2.57a4.757 4.757 0 00-8.462-4.324h-27.436a4.756 4.756 0 00-6.067-1.964l2.58 2.58a1.694 1.694 0 01-2.396 2.395l-.01-.009-2.57-2.57a4.757 4.757 0 004.342 6.703z"
        fill="#EDEDED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.114 95.354l-1.699-2.942a1.577 1.577 0 10-2.733 1.577l.01.017 1.689 2.926a4.431 4.431 0 112.733-1.578z"
        fill="#6E7893"
      />
      <path
        d="M133.95 187.027c-3.666-3.694-10.304-17.615-7.525-43.745 3.473-32.662 13.314-39.661 21.997-20.997m2.315-1.167l-4.631 2.333"
        stroke="#EDEDED"
      />
      <path d="M151.926 187.027h-26.163v2.379h26.163v-2.379z" fill="#EDEDED" />
      <path
        d="M144.524 179.995h-11.358v9.086h11.358v-9.086zM144.524 132.292h-11.358v9.086h11.358v-9.086zM142.252 116.391h-6.815v4.543h6.815v-4.543z"
        fill="#EDEDED"
      />
      <path
        d="M153.61 117.527h-29.53v2.272h29.53v-2.272zM142.252 133.428h-6.815v55.653h6.815v-55.653z"
        fill="#EDEDED"
      />
      <path d="M139.981 117.527h-2.272v19.308h2.272v-19.308z" fill="#EDEDED" />
    </svg>
  );
}

import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { A } from '@/components/atoms/A/A';
import { Container } from '@/components/atoms/Layout/Layout';
import { H1, P, Span } from '@/components/atoms/Typography/Typography';
import { IconBikeRepair } from '@/components/Icons/IconBikeRepair';
import { NavLink } from '@/components/local/NavLink/NavLink';
import nameSpacesConfig from '@/config/config.nameSpaces.json';
import { NavLinkTypeWithExternalLink } from '@/types/NavLinks';
import styles from './PageError.module.scss';

interface Props {
  link?: NavLinkTypeWithExternalLink;
  type: 'notFound' | 'common';
}

export const PageError = ({ type, link }: Props) => {
  const { t } = useTranslation([
    nameSpacesConfig.common,
    nameSpacesConfig.pages,
  ]);

  const typePrefixKey =
    type === 'common' ? 'common:applicationError' : `pages:${type}`;

  return (
    <Container className={styles.container} data-test-id="page-error">
      <div className={styles.left}>
        <FaExclamationTriangle className={styles.exclamationIcon} />
        <H1 version="level2" className={styles.header}>
          {t(`${typePrefixKey}.headingMain`)}
        </H1>
        <P>
          <Trans
            i18nKey={`${typePrefixKey}.info`}
            components={{
              navLink: !link ? (
                <Span />
              ) : (
                <NavLink color="primary" link={link} />
              ),
            }}
          />
        </P>
        <Link href="/" passHref prefetch={false}>
          <A withArrow version="level5" className={styles.link}>
            {t(`${typePrefixKey}.link`)}
          </A>
        </Link>
      </div>
      <div className={styles.right}>
        <IconBikeRepair className={styles.repairIcon} />
      </div>
    </Container>
  );
};
